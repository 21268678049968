<template>
  <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
              <v-col>
                    <v-card color="blue lighten-5">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="office" 
                                        :items="offices" 
                                        default="" 
                                        item-value="mill_id" 
                                        item-text="mill_name" 
                                        label="Mill ID"
                                        @change="(event) => GetSj(event)"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-text-field solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="docid" 
                                        :items="suratJl" 
                                        default="" 
                                        item-value="deliv_id" 
                                        item-text="deliv_id" 
                                        label="Surat Jalan" 
                                        clearable 
                                        >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="1">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="st_muat" 
                                        :items="muat" 
                                        default="" 
                                        item-value="value" 
                                        item-text="status" 
                                        label="Muat" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="1">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="st_type" 
                                        :items="types" 
                                        default="" 
                                        item-value="value" 
                                        item-text="status" 
                                        label="Cetak" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                </v-col>
                                 <v-col cols="12" xs="12" sm="3" md="1">
                                    <v-autocomplete solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="glendong" 
                                        :items="types" 
                                        default="" 
                                        item-value="value" 
                                        item-text="status" 
                                        label="Glondong ID" 
                                        clearable  
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="date_from"
                                        label="Ship From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-menu
                                    ref="modal"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        clearable
                                        label="Ship to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                    </v-menu>
                                </v-col>    
                                <v-row class="align-center mt-0 ma-0 pa-2">                            
                                <v-col cols="12" xs="12" sm="3" md="4">
                                    <v-btn class="mt-2 ml-2" 
                                    color="info" elevation="2" large @click="getPullData()">Search</v-btn>
                                </v-col>
                                </v-row>
                                 <v-row class="mt-2" style="padding-bottom:50px">
                                    <v-col cols="12">
                                        <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                                            <v-card-text>
                                                <h5>Result</h5>
                                                <v-data-table
                                                    dense
                                                    :headers="headers"
                                                    :items="menu_headers"
                                                    :loading="loading"
                                                    loading-text="Please wait, retrieving data"
                                                    class="elevation-1"
                                                    > 
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
        </v-row>
  </v-container>
</template>

<script>
    export default {
    data(){
        return {
            loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
                breadcumbs: [
                    {
                    text: 'KKA',
                    disabled: false,
                    href: '/admin/kka',
                    },
                    {
                    text: 'Reports',
                    disabled: false,
                    href: '#',
                    },
                    {
                    text: 'Delivery Report',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                    },
                ],
                menu_headers:[],
                menu_headerss:[],
                headers:[
                     {
                    text: 'Mill ID',
                    align: 'start',
                    sortable: true,
                    value: 'mill_id', 
                    align:'center'},
                    { text: 'Nama Customer', value: 'NamaCustomer' , align:'left'},
                    { text: 'Surat Jalan', value: 'noSJ' , align:'left'},
                    // { text: 'Remark', value: 'remark' , align:'left'},
                    { text: 'Tanggal Kirim', value: 'tglKirim' , align:'left'},
                    { text: 'Muat', value: 'muat' , align:'left'},
                    { text: 'Cetak', value: 'cetak' , align:'left'},
                    { text: 'Glondong ID', value: 'glondong_id' , align:'left'},
                    { text: 'Alamat Kirim', value: 'Alamat' , align:'left'},
                ],
                offices: [],
                suratJl: [],
                types: [
                {
                    status: 'YES',
                    value: 'Y'
                },
                {
                    status: 'NO',
                    value: 'N'
                }

                ],
                muat: [
                {
                    status: 'YES',
                    value: 'Y'
                },
                {
                    status: 'NO',
                    value: 'N'
                }

                ],
                office:'',
                datas:[],
                docid:'',
                remark:'',
                glendong:'',
                st_type:'',
                st_muat:'',
                date_from: '',
                date_to: '',
                modal_to:'',
                modal: false,
                loading3: false,
                dialog: false,
                modal2: false,    
        
            }
            
        },
    mounted(){
    this.GetMill()

    },
    methods:{
    close() {
        this.dialog = false
    },    
    async GetMill(){
       //  this.$store.dispatch('setOverlay', true)
        await axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliverReport/GetMill`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
            //console.log(res.data)
            this.offices = res.data.data
           //  this.$store.dispatch('setOverlay', false)
        })
            
    },  
    
     async GetSj(){
      //  this.loading = true
        // this.$store.dispatch('setOverlay', true)
        await axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliverReport/NoJalan?search=${this.$store.state.text}&mill_id=${this.office ? this.office : ''}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        .then(res => {
           //  this.loading = false
             this.$store.dispatch('setOverlay', false)
            this.suratJl = res.data.data
        })
            
    },    
    async getPullData(){
    this.loading = true
    //console.log(this.office)
    this.$store.dispatch('setOverlay', true)
        if (!this.office || !this.date_from ) {
        this.loading_dialog = false
            this.$store.dispatch('setOverlay', false)
            this.snackbar = {
            color: "error",
            icon: "mdi-checkbox-marked-circle",
            mode: "multi-line",
            position: "top",
            timeout: 7500,
            title: "Error",
            text: "Please Input Mill & Start Date",
            visible: true
        };
        } else {
        this.loading = false
        var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
        var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : new Date().toISOString().substr(0, 10).replace(/-/g,"");

        // var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
        // var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : '';
        await axios.get(`${process.env.VUE_APP_URL}/api/kka/DeliverReport/GetData?search=${this.$store.state.text}&mill_id=${this.office ? this.office : ''}&noSJ=${this.docid ? this.docid : ''}&stat=${this.st_muat ? this.st_muat : ''}&cetak=${this.st_type ? this.st_type : ''}&glondong_id=${this.glendong ? this.glendong : ''}&start_date=${startDate}&end_date=${endDate}`, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
        })
        
        .then(res => {
            console.log(res.data)
            this.$store.dispatch('setOverlay', false)
            this.loading = false
            this.menu_headers = res.data.data
        })

        }
    
    },   

    },    
   

    }
</script>

    <style>

    </style>